<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="이름을 정자로 입력하세요." class="cardClassDetailInfo" topClass="topcolor-orange">
        <template v-if="data.tbmCompleteFlag === 'Y'" slot="card-description">
          <font color="#C10015">
            ※ 완료된 TBM입니다.
          </font>
        </template>
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="data.tbmCompleteFlag !== 'Y'" label="저장" color="blue" :showLoading="false" @btnClicked="saveAsSvg" />
            <c-btn v-if="data.tbmCompleteFlag !== 'Y'" label="서명지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
               <VueSignaturePad id="signature" :width="windowWidth" height="200px" ref="signaturePad" />
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-card title="참석자 건강상태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            name="tbmName"
            label="TBM명"
            v-model="data.tbmName"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            :disabled="true"
            :range="true"
            name="tbmWorkDate"
            label="작업기간"
            v-model="data.tbmWorkDate"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="disabled"
            codeGroupCd="TBM_HEALTH_STATUS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="healthStatusCd"
            label="건강상태"
            v-model="data.healthStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :disabled="disabled"
            :isFlag="true"
            label="정신적피로"
            name="mentalFatigueFlag"
            v-model="data.mentalFatigueFlag"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :disabled="disabled"
            :isFlag="true"
            label="심리적피로"
            name="mentalPhychologicalFlag"
            v-model="data.mentalPhychologicalFlag"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :disabled="disabled"
            :isFlag="true"
            label="육체적피로"
            name="mentalPhysicalFlag"
            v-model="data.mentalPhysicalFlag"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :disabled="disabled"
            :isFlag="true"
            label="신체적결함"
            name="physicalDefectFlag"
            v-model="data.physicalDefectFlag"
          />
        </div>
      </template>
    </c-card>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import mobileConfig from '@/js/mobileConfig';
import selectConfig from '@/js/selectConfig';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          tbmId: '',
          regUserId: '',
          chgUserId: '',
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      updateSignatureUrl: '',
      windowWidth: '',
      data: {
        tbmAttendeeUserId: '',
        tbmId: '',
        tbmName: '',
        tbmWorkDate: '',
        electronSignature: '',
        mentalFatigueFlag: "N",
        mentalPhychologicalFlag: "N",
        mentalPhysicalFlag: "N",
        physicalDefectFlag: "N",
        tbmCompleteFlag: 'N',
        healthStatusCd: "THS0000001",
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.tbmCompleteFlag === 'Y'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.updateSignatureUrl = mobileConfig.sai.tbm.update.signature.url;
      this.getUrl = mobileConfig.sai.tbm.get.mobile.url;
      this.getUserDetail();
      if (!this.$refs.signaturePad) {
        return;
      }
      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad
      
      

    },
    getUserDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId, this.$store.getters.user.userId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data
            signaturePad.fromDataURL(this.data.electronSignature);
            this.getDetail();
          } else {
            this.data.tbmId = this.popupParam.tbmId
            this.data.tbmAttendeeUserId = this.$store.getters.user.userId

            this.getDetail();
          }
        })
      }
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(selectConfig.sai.tbm.get.url, this.popupParam.tbmId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            if (_result.data.tbmCompleteFlag === 'Y') {
              signaturePad.lockSignaturePad();
            }
            this.data.tbmName = _result.data.tbmName
            this.data.tbmWorkDate = [_result.data.tbmWorkStartDate,_result.data.tbmWorkEndDate]
          }
        })
      }
    },
    saveAsSvg() {
      var data = ''
      if (signaturePad.saveSignature().isEmpty) {
        data = ''
      } else {
        data = signaturePad.saveSignature().data;
      }
      this.data.electronSignature = data;
      
      if (!this.data.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '서명하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '서명등록하시겠습니까?(등록할 시 관련 교욱 참석이 완료 됩니다.)',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId
          this.data.chgUserId = this.$store.getters.user.userId
          this.$http.url = this.updateSignatureUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        
        },
      });
      }
    },
    eraseSignature() {
      signaturePad.clearSignature();
      this.data.electronSignature = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
  }
};
</script>
<style>
#signature {
  border: double 1px transparent;
  border-radius: 2;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>